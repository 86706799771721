/* Dark Mode */
.App {
  text-align: center;
  color: #e0e0e0;
  min-height: 100vh;
  padding: 20px;
  background-color: #121212;
  position: relative;
  overflow: hidden;
}

.background-image {
  position: fixed;
  /* Ensures the background is fixed */
  top: 0;
  left: 0;
  width: 100vw;
  /* Covers the full viewport width */
  height: 100vh;
  /* Covers the full viewport height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  /* Ensures content can scroll without affecting the background */
  min-height: 100vh;
  /* Ensures the content area is at least the height of the viewport */
}

.title-pane {
  background-color: rgba(33, 33, 33, 0.9);
  border-radius: 8px;
  padding: 10px 20px;
  width: fit-content;
  margin: 10px auto;
}

.title {
  font-size: 48px;
  color: gold;
  margin: 0;
}

.top-left-pane {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(33, 33, 33, 0.9);
  border-radius: 8px;
  padding: 10px 20px;
  z-index: 2;
}

.top-left-pane h2 {
  margin: 0;
  font-size: 18px;
  color: gold;
}

.search-container {
  position: relative;
  display: inline-block;
  width: auto;
  /* Adjust if necessary to fit your design */
  margin-bottom: 20px;
}

.search-input {
  font-size: 24px;
  padding: 12px;
  width: 100%;
  max-width: 600px;
  /* Ensures the input field does not exceed this width */
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  background-color: #424242;
  color: #e0e0e0;
  outline: none;
}

.dropdown {
  position: absolute;
  top: 100%;
  /* Positions right below the input field */
  left: 0;
  width: 100%;
  /* Matches the width of the search input */
  background-color: #424242;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  /* Ensure it is above other content */
  border-radius: 8px;
  overflow: hidden;
}

.dropdown-item {
  color: #e0e0e0;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #616161;
}

.dropdown-image {
  width: 40px;
  height: 60px;
  margin-right: 10px;
}

.details-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 20px;
  align-items: flex-start;
  /* Changed from default to flex-start to allow independent heights */
}

.text-pane {
  background-color: rgba(33, 33, 33, 0.9);
  border-radius: 8px;
  padding: 20px;
  color: #e0e0e0;
  flex: 1;
  margin-right: 10px;
}

.left-pane {
  margin-bottom: 20px;
}

.show-image {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  margin: 10px auto;
  display: block;
}

.cast-pane {
  cursor: pointer;
}

.cast-list {
  margin-top: 10px;
}

.cast-member {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cast-image {
  width: 50px;
  height: 75px;
  margin-right: 10px;
  border-radius: 4px;
}

.right-pane {
  flex: 1;
  background-color: rgba(33, 33, 33, 0.9);
  border-radius: 8px;
  padding: 20px;
  color: #e0e0e0;
}

/* Tabs Styles */
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.tab-button {
  flex: 1;
  background-color: #424242;
  color: #e0e0e0;
  border: 2px solid #616161;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.tab-button.active {
  background-color: #535353;
  border-color: gold;
  font-weight: bold;
}

.seasons {
  margin-top: 10px;
}

.season {
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #424242;
  border-radius: 8px;
  padding: 10px;
  color: #e0e0e0;
  transition: background-color 0.3s;
}

.season:hover {
  background-color: #535353;
}

h2,
h3,
h4,
p {
  margin: 10px 0;
}

h3 {
  display: flex;
  justify-content: flex-start;
  /* Changed from space-between to flex-start */
  align-items: center;
  background-color: inherit;
  color: #e0e0e0;
  padding: 10px;
  border-radius: 8px;
  user-select: none;
}

h3:hover {
  background-color: inherit;
}

h4 {
  cursor: pointer;
  margin-left: 20px;
}

h4:hover {
  text-decoration: underline;
}

.episodes p {
  margin-left: 40px;
}

.episode {
  background-color: #383838;
  margin: 5px 0 5px 20px;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.episode:hover {
  background-color: #494949;
}

.episode span {
  display: block;
  font-size: 0.9em;
  margin-left: 20px;
  color: #b0b0b0;
}

.episode h4 {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.episode h4:hover {
  text-decoration: none;
  /* Removes underline effect on hover */
}

.episode p {
  margin-left: 40px;
}

/* Indicator Styles */
.indicator {
  margin-right: 10px;
  cursor: pointer;
  width: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}

.season-indicator {
  padding: 5px 8px;
  /* Adjust sizing here */
  margin-right: 10px;
}

.episode-title {
  margin-left: 15px;
  /* Adjust to align text properly */
}

.watch-now {
  display: inline-flex;
  align-items: center;
  background-color: #424242;
  color: #e0e0e0;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;
  margin-top: 10px;
}

.streaming-logo {
  width: 24px;
  margin-right: 8px;
}

.video-preview {
  margin-top: 10px;
  width: 100%;
  max-width: 600px;
}

.video-iframe {
  width: 100%;
  height: 315px;
  border-radius: 8px;
}

/* Chatbox Styles */
.chatbox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #424242;
  border-radius: 8px;
  padding: 20px;
}

.chat-messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 400px;
  /* Adjust based on your design */
  overflow-y: auto;
  padding-right: 4px;
}

.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #333;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.chat-message {
  text-align: left;
  /* Ensure left alignment */
  padding: 10px;
  border-radius: 8px;
  background-color: #535353;
  color: #e0e0e0;
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* Add styles for bullet points if not already styled */
.chat-message ul {
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
  /* Adjust padding for bullets */
}

.chat-message li {
  margin-bottom: 5px;
}

.user-message {
  align-self: flex-end;
  background-color: #616161;
}

.system-message {
  align-self: flex-start;
  background-color: #757575;
}

.chat-input-container {
  display: flex;
  gap: 10px;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #616161;
  background-color: #535353;
  color: #e0e0e0;
  outline: none;
}

.send-button {
  padding: 10px 20px;
  background-color: gold;
  color: #424242;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #d4af37;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .details-container {
    flex-direction: column;
    align-items: center;
  }

  .text-pane,
  .right-pane {
    max-width: 100%;
    margin: 10px 0;
  }

  .show-image {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .search-input {
    width: 90%;
  }

  .show-image {
    width: 80%;
  }
}

/* About Button */
.about-button,
.privacy-policy-button {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: rgba(33, 33, 33, 0.9);
  color: gold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  z-index: 11;
  transition: background-color 0.3s;
}

.about-button {
  right: 10px;
}

.privacy-policy-button {
  right: 140px;
}

.about-button:hover,
.privacy-policy-button:hover {
  background-color: rgba(33, 33, 33, 0.7);
}

/* About Pane */
.about-pane,
.privacy-policy-pane {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  /* Set a fixed height */
  background-color: rgba(33, 33, 33, 0.9);
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 10;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-y: auto;
  /* Add scrolling */
}

.tmdb-logo {
  width: 100px;
  margin-top: 20px;
}

.close-about,
.close-privacy-policy {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #DC3545;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-about:hover,
.close-privacy-policy:hover {
  background-color: #c82333;
}

.privacy-policy {
  background-color: rgba(33, 33, 33, 0.9);
  border-radius: 8px;
  padding: 20px;
  color: #e0e0e0;
  margin: 20px auto;
  max-width: 800px;
  text-align: left;
}

.privacy-policy h2 {
  color: gold;
}

.privacy-policy h3 {
  margin-top: 20px;
}

.privacy-policy p {
  margin-bottom: 10px;
}

.privacy-policy-link {
  display: block;
  margin-top: 20px;
  color: gold;
  text-decoration: none;
  cursor: pointer;
}

.privacy-policy-link:hover {
  text-decoration: underline;
}

.buttons-container {
  display: flex;
  gap: 10px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 11;
}